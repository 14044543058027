.TourPage {
  background-color: #f8f8f6;

  .tour-con {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // min-height: 60vh;
    padding-bottom: 4rem;
    .upcoming {
      font-family: "righteous", cursive;
      margin: 2rem;
      font-size: 30px;
      text-align: center;
    }
    .tour-comps {
      min-height: 60vh;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
}

@media only screen and (max-width: 720px) {
  .TourPage {
    .tour-con {
      padding: 1rem;
    }
  }
}
