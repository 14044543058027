.ImageGrid {
  .item {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
    img {
      width: 500px;
      height: 333px;
      padding: 0.5rem;
    }
  }
}
@media only screen and (max-width: 1024px) {
  .ImageGrid {
    .item {
      img {
        width: 400px;
      }
    }
  }
}
@media only screen and (max-width: 900px) {
  .ImageGrid {
    .item {
      img {
        width: 300px;
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .ImageGrid {
    .item {
      img {
        width: 90%;
      }
    }
  }
}
