.ImageDownComp {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  .asset {
    border: 1px solid rgba(0, 0, 0, 0.12);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    margin: 1rem;
    padding: 1rem;
    .asset-img {
      width: 100%;
      max-width: 300px;
    }
    .bot-sec {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .name {
        font-family: "Source Sans Pro", sans-serif;
        font-size: medium;
      }
    }
  }
}
