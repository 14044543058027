.ImageTimeline {
  background-color: transparent;
  // min-width: 260px;
  // min-height: 325px;
  // max-width: 70vw;
  // max-height: 90vh;
  margin: 20px 10px;
  // padding: 0.5rem;
  font-family: "Source Sans Pro", sans-serif;
  font-size: medium;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  .fullwidth {
    object-fit: contain;
    max-width: 950px;
    max-height: 950px;
    width: 100%;
  }
  .notfullwidth {
    object-fit: contain;
    width: 100%;
    max-width: 300px;
    height: 300px;
  }
  .bot-sec {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0.4rem;
    word-break: break-all;
    .date {
      font-family: "Source Sans Pro", sans-serif;
      color: #1c1c1c;
      font-size: 14px;
      // font-weight: 600;
    }
    .place {
      font-family: "Source Sans Pro", sans-serif;
      color: #1c1c1c;
      font-size: 14px;
      // font-weight: 600;
    }
  }
  .modal {
    z-index: 500;
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    // padding-top: 80px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
    @media (max-width: 800px) {
      // padding-top: 70px;
    }
  }

  /* Modal Content */
  .modal-content {
    border-radius: 8px;
    z-index: 5000;
    position: relative;
    background-color: #fefefe;
    margin: auto;
    padding: 0;
    // border: 1px solid #888;
    max-width: 1000px;
    max-height: 80vh;
    overflow-y: auto;
    scrollbar-width: thin;
    width: 90%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    -webkit-animation-name: animatetop;
    -webkit-animation-duration: 0.4s;
    animation-name: animatetop;
    animation-duration: 0.4s;
  }
  .modal-content::-webkit-scrollbar {
    width: 2px;
    background-color: #cacaca60;
  }
  .modal-content::-webkit-scrollbar-thumb {
    background: #757575;
  }
  /* Add Animation */
  @-webkit-keyframes animatetop {
    from {
      top: -300px;
      opacity: 0;
    }
    to {
      top: 0;
      opacity: 1;
    }
  }

  @keyframes animatetop {
    from {
      top: -300px;
      opacity: 0;
    }
    to {
      top: 0;
      opacity: 1;
    }
  }

  /* The Close Button */
  .close {
    color: white;
    float: right;
    font-size: 30px;
    font-weight: bold;
  }

  .close:hover,
  .close:focus {
    color: #ff001a;
    text-decoration: none;
    cursor: pointer;
  }

  .modal-header {
    padding: 0 16px;
    background-color: #000000;
    color: white;
    // border-radius: 8px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // h3 {
    //   margin: 0;
    //   line-height: normal;
    // }
  }
  .modal-body {
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 500;
    font-size: 16px;
    padding: 2px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 1rem 0;

    p {
      padding: 0rem;
      text-align: justify;
      width: 90%;
    }
    a {
      color: red;
    }
    .img-con {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      img {
        max-width: 400px;
        width: 100%;
        height: auto;
        margin: 1rem;
        // max-height: 300px;
        // object-fit: cover;
      }
    }
  }

  .modal-footer {
    padding: 2px 16px;
    background-color: #5cb85c;
    color: white;
  }
}
