.flip-cardd {
  background-color: transparent;
  width: 260px;
  height: 325px;
  margin: 20px 10px;
  cursor: pointer;
  /* The Modal (background) */
  .modal {
    z-index: 500;
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    // padding-top: 80px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
    @media (max-width: 800px) {
      // padding-top: 70px;
    }
  }

  /* Modal Content */
  .modal-content {
    border-radius: 8px;
    z-index: 5000;
    position: relative;
    background-color: #fefefe;
    margin: auto;
    padding: 0;
    // border: 1px solid #888;
    max-width: 1080px;
    max-height: 80vh;
    overflow-y: auto;
    width: 90%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    -webkit-animation-name: animatetop;
    -webkit-animation-duration: 0.4s;
    animation-name: animatetop;
    animation-duration: 0.4s;
  }

  /* Add Animation */
  @-webkit-keyframes animatetop {
    from {
      top: -300px;
      opacity: 0;
    }
    to {
      top: 0;
      opacity: 1;
    }
  }

  @keyframes animatetop {
    from {
      top: -300px;
      opacity: 0;
    }
    to {
      top: 0;
      opacity: 1;
    }
  }

  /* The Close Button */
  .close {
    color: white;
    float: right;
    font-size: 30px;
    font-weight: bold;
  }

  .close:hover,
  .close:focus {
    color: #ff001a;
    text-decoration: none;
    cursor: pointer;
  }

  .modal-header {
    padding: 0 16px;
    background-color: #000000;
    color: white;
    // border-radius: 8px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    h3 {
      margin: 0;
      line-height: normal;
    }
  }
  .modal-body {
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 500;
    font-size: 16px;
    padding: 2px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 1rem 0;

    p {
      padding: 0rem;
      text-align: justify;
      width: 90%;
    }
    a {
      color: red;
    }
    .img-con {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      img {
        max-width: 300px;
        width: 100%;
        height: auto;
        margin: 1rem;
        // max-height: 300px;
        // object-fit: cover;
      }
    }
  }

  .modal-footer {
    padding: 2px 16px;
    background-color: #5cb85c;
    color: white;
  }
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0px 3.44px 17.2px rgba(0, 0, 0, 0.2);

  border-radius: 8px;
}

.flip-cardd:hover .flip-card-back {
  // transform: rotateY(180deg);
  opacity: 1;
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  // -webkit-backface-visibility: hidden;
  // backface-visibility: hidden;
}

.flip-card-front {
  img {
    object-fit: cover;
    width: 260px;
    height: 260px;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
  }
  .name {
    font-family: "Source Sans Pro", sans-serif;
    color: #1c1c1c;
    font-size: 18px;
    padding-bottom: 0.5rem;
    // font-weight: 700;
  }
  // .sub {
  //   font-family: "Source Sans Pro", sans-serif;
  //   color: rgba(0, 0, 0, 0.5);
  //   font-size: 16px;
  //   padding-bottom: 0.5rem;
  //   // font-weight: 600;
  // }
}

.flip-card-back {
  height: 260px;
  opacity: 0;
  background-color: #000000aa;
  transition: all ease 0.3s;
  // transform: rotateY(180deg);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  .about {
    font-family: "Source Sans Pro", sans-serif;
    color: #ffffff;
    font-size: 18px;
    // font-weight: 500;
    padding: 0 1rem;
  }
}
