.OoraliExpressPage {
  // padding-top: 68px;
  background-color: #f8f8f6;

  .image-con {
    width: 100%;
    img {
      width: 100%;
      max-height: 75vh;
      object-fit: cover;
      // object-position: bottom;
      // margin-top: -12rem;
    }
  }
  .about {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 2rem 0;
    span {
      width: 90%;
      max-width: 720px;
      line-height: 30px;
      font-family: "Source Sans Pro", sans-serif;
      // font-weight: 600;
      text-align: justify;
      padding-bottom: 1rem;
    }
  }
  .video-sec {
    padding: 2rem 4rem;
    @media (max-width: 720px) {
      padding: 2rem 1rem;
    }
    iframe {
      width: 100%;
      height: 80vh;
      border-radius: 8px;
      @media (max-width: 1000px) {
        height: 60vh;
      }
      @media (max-width: 720px) {
        height: 40vh;
      }
    }
  }
  .title {
    font-family: "righteous", cursive;
    font-size: x-large;
    font-weight: 400;
    text-align: center;
    padding: 1rem;
  }
  .projectcard-sec {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    .card {
      margin: 10px;
    }
  }
}
