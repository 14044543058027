.SlickComp {
  .title-style {
    font-family: "righteous", cursive;
    font-size: x-large;
    font-weight: 400;
    padding: 0rem 2rem;
  }
  .center {
    .slick-prev:before,
    .slick-next:before {
      color: #ff001a;
      font-size: 2rem;
      opacity: 1;
    }
    .slick-prev {
      left: 0px;
      z-index: 10;
    }
    .slick-next {
      right: 10px;
      z-index: 10;
    }
    .slick-next:before,
    .slick-next:before {
      color: #ff001a;
      font-size: 2rem;
      opacity: 1;
    }
  }
}
