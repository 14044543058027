.ExpressCarComp {
  // margin: 4rem 0;
  padding: 6rem 4rem;
  @media (max-width: 720px) {
    padding: 2rem 1rem;
  }
  // background-color: black;
  .section {
    display: flex;
    align-items: center;
    justify-content: center;
    .image-express {
      width: 100%;
      // max-width: 800px;
      max-height: 80vh;
      object-fit: contain;
    }
  }
}
