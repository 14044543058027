.BandMemberComp {
  width: 260px;
  cursor: pointer;
  margin: 2rem 1rem;
  .main-sec {
    margin-bottom: 200px;
    position: relative;

    transition: all ease-in-out 0.3s;
    .img-con {
      height: 325px;
      overflow: hidden;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      img {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        width: 100%;
        height: 325px;
        object-fit: cover;
        z-index: 2;
        transition: all ease-in-out 0.3s;
      }
    }

    .color-screen {
      position: absolute;
      top: 0;
      width: 260px;
      height: 325px;

      z-index: 4;
      visibility: hidden;
      opacity: 0;
      transition: all ease-in-out 0.3s;
    }
    .mem-name {
      text-align: center;
      font-size: 26px;
      letter-spacing: 1px;
      // transition: all ease-in-out 0.3s;
    }
    .mem-about {
      // position: absolute;
      // bottom: 0;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;

      font-family: "Source Sans Pro", sans-serif;
      color: #1c1c1c;
      text-align: justify;
      padding: 10px;
      visibility: hidden;
      opacity: 0;
      z-index: 0;
      margin-top: -200px;
      height: 0;
      transition: all ease-in-out 0.3s;
    }
  }
  .main-sec:hover {
    // box-shadow: 0px 3.44px 20px rgba(0, 0, 0, 0.6);

    transform: scale(1.05);
  }

  .main-sec-hover {
    position: relative;

    margin-bottom: 0px;
    img {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      width: 100%;
      height: 325px;
      object-fit: cover;
      z-index: 2;

      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
    }
    .color-screen {
      position: absolute;
      top: 0;
      width: 260px;
      height: 325px;
      z-index: 4;
      transition: all ease-in-out 0.3s;

      visibility: visible;
      opacity: 0.7;
    }
    .mem-name {
      text-align: center;
      font-size: 26px;
      letter-spacing: 1px;

      position: absolute;
      top: 0;
      width: 260px;
      height: 300px;
      font-size: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 5;
      color: #fff;
    }
    .mem-about {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      font-family: "Source Sans Pro", sans-serif;
      color: #252525;
      text-align: justify;
      padding: 20px;
      z-index: 0;
      transition: all ease-in-out 0.3s;
      height: auto;
      margin-top: -5px;
      visibility: visible;
      opacity: 1;
    }
  }
}
