.ShopPage {
  background-color: #f8f8f6;

  .shop-sec {
    min-height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
