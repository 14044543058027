.VideoComp {
  margin: 1rem;
  color: #fff;
  box-shadow: 0px 3.44px 17.2px rgba(0, 0, 0, 0.2);
  border-radius: 10.32px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  cursor: pointer;
  transition: all ease-in-out 0.3s;
  a {
    text-decoration: none;
    .image-con {
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        // width: 100%;
        width: 300px;
        height: 200px;
        object-fit: cover;
      }
      .hover-play {
        position: absolute;
        width: 50px;
        height: 50px;
        transition: all ease-in-out 0.3s;
        opacity: 0;
      }
    }
    .name {
      font-family: "Source Sans Pro", sans-serif;
      font-size: 16px;
      font-weight: 600;
      padding: 5px 0;
    }
  }
}
.VideoComp:hover {
  box-shadow: 0px 3.44px 17.2px #d50000;
  a {
    .music-image {
      filter: brightness(50%);
    }
    .hover-play {
      opacity: 1;
    }
  }
}
@media only screen and (max-width: 450px) {
  .VideoComp {
    max-width: 300px;
  }
}
