.Homepage {
  /* The Modal (background) */
  background-color: #f8f8f6;
  .modal {
    z-index: 5000;
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    // padding-top: 100px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
    @media (max-width: 800px) {
      // padding-top: 70px;
    }
  }

  /* Modal Content */
  .modal-content {
    border-radius: 8px;
    z-index: 5000;
    position: relative;
    background-color: #fefefe;
    margin: auto;
    padding: 0;
    // border: 1px solid #888;
    max-width: 720px;
    width: 90%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    -webkit-animation-name: animatetop;
    -webkit-animation-duration: 0.4s;
    animation-name: animatetop;
    animation-duration: 0.4s;
  }

  /* Add Animation */
  @-webkit-keyframes animatetop {
    from {
      top: -300px;
      opacity: 0;
    }
    to {
      top: 0;
      opacity: 1;
    }
  }

  @keyframes animatetop {
    from {
      top: -300px;
      opacity: 0;
    }
    to {
      top: 0;
      opacity: 1;
    }
  }

  /* The Close Button */
  .close {
    color: white;
    float: right;
    font-size: 30px;
    font-weight: bold;
  }

  .close:hover,
  .close:focus {
    color: #ff001a;
    text-decoration: none;
    cursor: pointer;
  }

  .modal-header {
    padding: 0 16px;
    background-color: #000000;
    color: white;
    // border-radius: 8px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    h3 {
      margin: 0;
      line-height: normal;
    }
  }

  .modal-body {
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 500;
    font-size: 16px;
    padding: 2px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 1rem 0;
    p {
      padding: 1rem;
      text-align: justify;
      width: 90%;
    }
    a {
      color: red;
    }
  }

  .modal-footer {
    padding: 2px 16px;
    background-color: #5cb85c;
    color: white;
  }

  // ------------------------------homepage img--------------------
  .ellipse {
    // background-image: url("../images/Homepage.png");
    // padding-top: 66.84;
    height: calc(100vh - 66.84px);
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      cursor: pointer;
      height: auto;
      width: 80%;
      max-width: 250px;
      // mix-blend-mode: multiply;
      @media (max-width: 640px) {
        max-width: 200px;
      }
    }
  }
  // --------------------------------carousel--------------------------------
  .latest-happ {
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: "righteous", cursive;
      font-size: x-large;
      font-weight: 400;
      padding-top: 2rem;
    }
    .slick-car {
      // background-color: rgba(255, 0, 0, 0.3);
      border-radius: 8px;
      margin: 0rem 4rem;
      margin-bottom: 2rem;
      @media (max-width: 720px) {
        margin: 2rem 0rem;
      }
    }
  }

  // ---------------------------featured-video----------------------------------
  .featured-video {
    padding: 2rem 4rem;
    @media (max-width: 720px) {
      padding: 2rem 1rem;
    }
  }
  // ---------------------------------------oorali-express----------------------
  .oorali-express {
    margin: 2rem 4rem;
    // background-image: url("../images/oorali-express.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 80vh;
    position: relative;
    border-radius: 8px;
    @media (max-width: 720px) {
      margin: 2rem 0rem;
      height: 60vh;
    }
    @media (max-width: 550px) {
      margin: 2rem 0rem;
      height: 40vh;
    }
    img {
      width: 100%;
      height: auto;
    }
    .oorali-text {
      position: absolute;
      max-width: 100%;
      width: 480px;
      top: 5%;
      left: 50%;
      transform: translate(-50%, 0);
      @media (max-width: 550px) {
        color: white;
      }
      .title {
        font-family: "righteous", cursive;
        font-size: xx-large;
      }
      .sub {
        text-align: justify;
        font-family: "Source Sans Pro", sans-serif;
        // font-weight: bold;
        margin: 0.5rem 0;
      }
      .read-more {
        margin: 0.5rem 0;
        .new-btn-sec {
          background-color: transparent;
          border: 1px solid #ff0000;
          color: #ff0000;
          padding: 8px;
          font-size: 14px;
          font-weight: 400;
          font-family: "righteous", cursive;
          cursor: pointer;
          border-radius: 8px;
          transition: 0.3s;
        }
        .new-btn-sec:hover {
          color: #ffffff;
          background-color: #ff0000;
        }
      }
    }
  }
  // ---------------------------email-------------------------

  .oorali-tribe {
    padding: 4rem;
    @media (max-width: 720px) {
      padding: 2rem 0rem;
    }

    display: flex;
    align-items: center;
    justify-content: center;
    .cyan-color-con {
      width: 100%;
      max-width: 1080px;
      border-radius: 8px;
      background: #1fc58e;
      display: flex;
      align-items: center;

      .left-sec {
        display: flex;
        justify-content: center;
        width: 50%;
        img {
          // max-width: 400px;
          // width: 100%;
          height: 235px;
        }
      }
      .right-sec {
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        .title {
          font-family: "Source Sans Pro", sans-serif;
          // width: 300px;
          margin: 0.5rem;
          text-align: center;
          font-weight: bolder;
        }
        form {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          .email {
            width: 282px;
            margin: 0.5rem;
            padding: 16px 8px;
            color: white;
            // font-family: "righteous";
            font-weight: bold;
            border: 1px solid white;
            border-radius: 8px;
            background: transparent;
          }
          .email-btn {
            width: 300px;
            margin: 0.5rem;
          }
        }
      }
    }
  }
}

@media (max-width: 1000px) {
  .Homepage {
    .oorali-express {
      .oorali-text {
        .title {
          font-size: x-large;
        }
      }
    }
  }
}

@media (max-width: 900px) {
  .Homepage {
    .oorali-tribe {
      .cyan-color-con {
        justify-content: center;
        align-items: center;
        flex-direction: column-reverse;
        .left-sec {
          justify-content: center;
          width: 100%;
        }
        .right-sec {
          margin-top: 2rem;
          .title {
            // width: 400px;
          }
          form {
            .email {
              width: 382px;
            }
            .email-btn {
              width: 400px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 500px) {
  .Homepage {
    .oorali-express {
      .oorali-text {
        width: 300px;
        .title {
          font-size: larger;
        }
      }
    }
    .oorali-tribe {
      // margin: 4rem 1rem;
      .cyan-color-con {
        .left-sec {
        }
        .right-sec {
          .title {
            width: 250px;
          }
          form {
            .email {
              width: 232px;
            }
            .email-btn {
              width: 250px;
            }
          }
        }
      }
    }
  }
}
