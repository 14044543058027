.VideoSlickComp {
  .title-style {
    font-family: "righteous", cursive;
    font-size: x-large;
    font-weight: 400;
    padding-bottom: 2rem;
    text-align: center;
  }
  iframe {
    width: 100%;
    height: 80vh;
    border-radius: 8px;
    @media (max-width: 1000px) {
      height: 60vh;
    }
    @media (max-width: 720px) {
      height: 40vh;
    }
  }
  .center {
    .slick-prev:before,
    .slick-next:before {
      color: #ffd700;
      font-size: 2rem;
      opacity: 0;
      transition: all ease-in-out 0.3s;
    }
    .slick-prev {
      left: 10px;
      z-index: 10;
    }
    .slick-next {
      right: 24px;
      z-index: 10;
    }
    .slick-next:before,
    .slick-next:before {
      color: #ffd700;
      font-size: 2rem;
      opacity: 0;
      transition: all ease-in-out 0.3s;
    }
    &:hover {
      .slick-prev:before,
      .slick-next:before {
        opacity: 1;
      }
      .slick-next:before,
      .slick-next:before {
        opacity: 1;
      }
    }
  }
}
