.SlickCardComp {
  width: 260px;
  height: 350px;
  //   width: 344.29px;
  //   height: 445px;
  background: #ffffff;
  box-shadow: 0px 3.44px 17.2px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 20px;
  transition: all ease-in-out 0.3s;

  img {
    object-fit: cover;
    width: 260px;
    height: 260px;
  }
  .date {
    font-family: "Source Sans Pro", sans-serif;
    color: rgba(0, 0, 0, 0.38);
    font-size: 12px;
    padding: 0.5rem 0;
  }
  .title {
    font-family: "Source Sans Pro", sans-serif;
    color: #000000;
    font-size: 16px;
    line-height: 18px;
    height: 55px;
    overflow: hidden;
  }
}
.SlickCardComp:hover {
  box-shadow: 0px 3.44px 17.2px rgba(0, 0, 0, 0.6);
}
