.ModalComp {
  /* The Modal (background) */
  .modal {
    z-index: 5000;
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    // padding-top: 100px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
    @media (max-width: 800px) {
      // padding-top: 70px;
    }
  }

  /* Modal Content */
  .modal-content {
    border-radius: 8px;
    z-index: 5000;
    position: relative;
    background-color: #fefefe;
    margin: auto;
    padding: 0;
    // border: 1px solid #888;
    max-width: 520px;
    width: 90%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    -webkit-animation-name: animatetop;
    -webkit-animation-duration: 0.4s;
    animation-name: animatetop;
    animation-duration: 0.4s;
  }

  /* Add Animation */
  @-webkit-keyframes animatetop {
    from {
      top: -300px;
      opacity: 0;
    }
    to {
      top: 0;
      opacity: 1;
    }
  }

  @keyframes animatetop {
    from {
      top: -300px;
      opacity: 0;
    }
    to {
      top: 0;
      opacity: 1;
    }
  }

  /* The Close Button */
  .close {
    color: white;
    float: right;
    font-size: 30px;
    font-weight: bold;
  }

  .close:hover,
  .close:focus {
    color: #ff001a;
    text-decoration: none;
    cursor: pointer;
  }

  .modal-header {
    padding: 0 16px;
    background-color: #000000;
    color: white;
    // border-radius: 8px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    h3 {
      margin: 0;
      line-height: normal;
    }
  }

  .modal-body {
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 500;
    font-size: 16px;
    padding: 2px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 1rem 0;
    p {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0rem;
      text-align: center;
      width: 100%;
      span {
        padding-left: 5px;
      }
    }
    a {
      color: red;
    }
  }

  .modal-footer {
    padding: 2px 16px;
    background-color: #5cb85c;
    color: white;
  }
}
