.MediaPage {
  background-color: #f8f8f6;

  .media-con {
    .media-video-con {
      background-color: black;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding-bottom: 4rem;
      .video-title {
        font-family: "righteous", cursive;
        margin: 2rem;
        font-size: 30px;
        text-align: center;
      }
      .videos-con {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        flex-wrap: wrap;
        padding-bottom: 2rem;
      }
      .view-more-con {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 2rem;
        .view-more {
          font-family: "Righteous", cursive;
          background: none;
          color: white;
          padding: 1rem;
          border: 2px solid red;
          font-size: large;
          cursor: pointer;
        }
      }
    }
    .media-image-con {
      background-color: #f8f8f8;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding-bottom: 4rem;
      .image-title {
        font-size: x-large;
        margin: 4rem 0;
      }
      .image-con {
        padding-bottom: 4rem;
      }
      .view-more-con {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 2rem;
        .view-more {
          font-family: "Righteous", cursive;
          background: none;
          color: black;
          padding: 1rem;
          border: 2px solid red;
          font-size: large;
          cursor: pointer;
        }
      }
    }
    .media-assets-con {
      background-color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding-bottom: 4rem;
      .assets-title {
        font-size: x-large;
        margin: 4rem 0;
      }
      .assets-con {
        padding-bottom: 4rem;
      }
      .view-more-con {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 2rem;
        .view-more {
          font-family: "Righteous", cursive;
          background: none;
          color: black;
          padding: 1rem;
          border: 2px solid red;
          font-size: large;
          cursor: pointer;
        }
      }
    }
  }
}
