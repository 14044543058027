.AboutUs {
  background-color: black;
  padding: 1rem 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  @media (max-width: 850px) {
    flex-direction: column;
  }
  .left-abt {
    width: 50%;
    @media (max-width: 850px) {
      width: 100%;
    }
    .about-us-title {
      text-align: center;
      padding: 0.5rem 0;
      letter-spacing: 1px;
      span {
        color: white;
        font-size: 18px;
      }
    }
    .social-icons-footer {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      .cir-btn-con {
        cursor: pointer;
        margin: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 48px;
        height: 48px;
        border-radius: 48px;
        background-color: rgba(255, 255, 255, 0.08);
        img {
          width: 24px;
        }
        @media (max-width: 480px) {
          margin: 0.5rem;
          width: 40px;
          height: 40px;
          border-radius: 40px;
          img {
            width: 20px;
          }
        }
      }
    }
    .btn-con-new {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .right-abt {
    width: 50%;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media (max-width: 850px) {
      width: 100%;
      margin-top: 1rem;
    }
    .title {
      text-align: center;
      padding: 0.5rem 0;
      letter-spacing: 1px;
      span {
        color: white;
        font-size: 18px;
      }
    }
    form {
      display: flex;
      flex-direction: column;
      align-items: center;
      // @media (max-width: 850px) {
      //   flex-direction: row;
      // }
      .email {
        height: 36px;
        width: 300px;
        background-color: transparent;
        border: 1px solid white;
        border-radius: 8px;
        color: white;
        margin: 0.8rem;
        padding-left: 10px;
        @media (max-width: 850px) {
          height: 34px;
          width: 250px;
        }
      }
      .new-btn-pri-small {
        width: 117.63px;
      }
    }
  }
}
