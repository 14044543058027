html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Righteous", cursive;
  overflow-x: hidden;
}
*:focus {
  outline: none;
}
button:focus {
  outline: none;
}
input:focus {
  outline: none;
}
a {
  color: inherit;
  text-decoration: none;
}
.new-btn-pri {
  min-height: 50px;
  min-width: 150px;
  background-color: #ff0000;
  border: 4px solid #ff0000;
  color: white;
  font-size: larger;
  font-weight: 400;
  font-family: "righteous", cursive;
  cursor: pointer;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  transition: 0.3s;
}
.new-btn-pri:hover {
  background-color: #cf0108;
  border: 4px solid #cf0108;
}

.new-btn-pri-small {
  min-height: 30px;
  min-width: 100px;
  background-color: #ff0000;
  border: 4px solid #ff0000;
  color: white;
  font-size: 16px;
  font-weight: 400;
  font-family: "righteous", cursive;
  cursor: pointer;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  transition: 0.3s;
}
.new-btn-pri-small:hover {
  background-color: #cf0108;
  border: 4px solid #cf0108;
}

/* music player playlist */
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .player-content
  .audio-lists-btn {
  display: none !important;
}
