.MusicPage {
  background-color: #f8f8f6;
  .music-sec {
    min-height: 60vh;
    padding-bottom: 2rem;
    background-color: black;
    display: flex;
    flex-direction: column;
    .music-title {
      color: white;
      font-family: "righteous", cursive;
      margin: 2rem;
      font-size: 30px;
      text-align: center;
    }
    .playlist-sec {
      padding: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      @media (max-width: 720px) {
        padding: 1rem;
        // justify-content: center;
      }
    }
    .playing-now-sec {
      padding: 0 1rem;
      padding-bottom: 2rem;
      .title {
        text-align: center;
        font-family: "righteous", cursive;
        font-size: x-large;
        padding: 2rem 0rem;
        color: white;
      }
      .player-sec {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .img-name {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 100%;
          max-width: 300px;
          img {
            border-radius: 8px;
            width: 100%;
            margin-bottom: 1rem;
          }
          .name {
            padding: 0.2rem;
            font-size: 20px;
            color: #d50000;
          }
          .year {
            padding: 0.2rem;
            color: #d50000;
          }
        }
        .lyrics {
          margin-top: 1rem;
          width: 100%;
          max-width: 600px;
          span {
            font-family: "Source Sans Pro", sans-serif;
            font-weight: 600;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 40px;
            letter-spacing: 0.25px;
            color: white;
            @media (max-width: 600px) {
              font-size: 14px;
              line-height: 30px;
            }
            @media (max-width: 400px) {
              font-size: 12px;
              line-height: 25px;
            }
          }
        }
      }
    }

    .player {
      width: 100%;
      position: fixed;
      bottom: -5px;
      z-index: 5;
    }
  }
  .bot-space {
    height: 120px;
  }
  .donate {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: #f8f8f8;
    padding: 4rem 0rem;
    span {
      width: 90%;
      max-width: 600px;
      font-family: "Source Sans Pro", sans-serif;
      padding-bottom: 2rem;
      font-size: 16px;
      line-height: 24px;
      text-align: justify;
      letter-spacing: 0.15px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.6);
    }
  }
}

.modal {
  z-index: 5000;
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  // padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  @media (max-width: 800px) {
    // padding-top: 70px;
  }
}

/* Modal Content */
.modal-content {
  border-radius: 8px;
  z-index: 5000;
  position: relative;
  background-color: #fefefe;
  margin: auto;
  padding: 0;
  // border: 1px solid #888;
  max-width: 550px;
  width: 90%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s;
}

/* Add Animation */
@-webkit-keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

/* The Close Button */
.close {
  color: white;
  float: right;
  font-size: 30px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #ff001a;
  text-decoration: none;
  cursor: pointer;
}

.modal-header {
  padding: 0 16px;
  background-color: #000000;
  color: white;
  // border-radius: 8px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  h3 {
    margin: 0;
    line-height: normal;
  }
}

.modal-body {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 500;
  font-size: 16px;
  padding: 2px 16px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  padding: 1rem 0;
  .bank {
    font-weight: 600;
    font-size: 20px;
    padding-bottom: 1rem;
    color: #ff001a;
  }
  .bank-details {
    font-size: 18px;
    padding-bottom: 1rem;
    display: flex;
    // flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    span {
      padding-left: 0.5rem;
      font-weight: 600;
      color: #1c1c1c;
      letter-spacing: 1px;
    }
  }
}

.modal-footer {
  padding: 2px 16px;
  background-color: #5cb85c;
  color: white;
}
