.PlayListComp {
  margin: 1rem 2rem;
  color: #fff;
  box-shadow: 0px 3.44px 17.2px rgba(0, 0, 0, 0.2);
  border-radius: 10.32px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  cursor: pointer;
  transition: all ease-in-out 0.3s;

  .image-con {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      // width: 100%;
      width: 300px;
      height: 200px;
      object-fit: cover;
      @media (max-width: 570px) {
        width: 250px;
        height: 166px;
      }
      @media (max-width: 400px) {
        width: 200px;
        height: 133px;
      }
    }
    .hover-play {
      position: absolute;
      width: 50px;
      height: 50px;
      transition: all ease-in-out 0.3s;
      opacity: 0;
    }
  }
  .name {
    font-family: "Source Sans Pro", sans-serif;
    font-size: 16px;
    font-weight: 600;

    line-height: 18px;
    padding: 5px 0;
  }
  .sub {
    font-family: "Source Sans Pro", sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 29px;
  }
}
.PlayListComp:hover {
  box-shadow: 0px 3.44px 17.2px #d50000;
  .music-image {
    filter: brightness(50%);
  }
  .hover-play {
    opacity: 1;
  }
}

.now-playing {
  margin: 1rem 2rem;
  background: #d50000;
  box-shadow: 0px 3.44px 17.2px #d50000;
  border-radius: 10.32px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  transition: all ease-in-out 0.3s;
  img {
    // width: 100%;
    width: 300px;
    height: 200px;
    object-fit: cover;
  }
  .name {
    font-family: "Source Sans Pro", sans-serif;
    color: #ffffff;
    font-size: 16px;
    line-height: 18px;
    font-weight: 600;
    padding: 5px 0;
  }
  .bot-sec {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .sub {
      font-family: "Source Sans Pro", sans-serif;
      color: #ffffff;
      font-size: 14px;
      font-weight: 600;
      line-height: 25px;
    }
    .now {
      img {
        width: 25px;
        height: 25px;
      }
    }
  }
}
