.TimelinePage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f8f8f6;
  .timeline-sec {
    width: 100%;
    max-width: 1920px;
    min-height: 80vh;
    display: flex;
    flex-direction: column;
    .timeline-title {
      font-family: "righteous", cursive;
      margin-top: 2rem;
      font-size: 30px;
      text-align: center;
    }

    .main-timeline-con {
      display: flex;
      // flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      .date-con {
        border-right: 2px solid rgba(0, 0, 0, 0.2);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 10%;
        // max-width: 100px;
        position: fixed;
        left: 0;
        top: 30%;
        a {
          font-weight: 700;
          padding: 1rem 0.5rem;
          color: rgba(0, 0, 0, 0.6);
          transition: color, font-size ease-in-out 0.2s;
        }
        a:hover,
        a:focus,
        a:active {
          font-size: x-large;
          color: rgb(0, 0, 0);
        }
      }
      .timeline-con {
        // max-height: 150vh;
        // overflow: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 1200px;
        padding-left: 10%;
        .yearly-timeline {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 100%;
          padding-top: 4rem;
          .year {
            padding: 1rem 0;
            width: 90%;
            font-size: x-large;
            font-weight: 700;
            color: black;
            border-bottom: 2px solid rgba(0, 0, 0, 0.2);
            span {
              font-weight: 300;
              color: #00000060;
              padding: 0px 0.5rem;
            }
          }
          .card-sec {
            // padding: 1rem 0;
            width: 90%;
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            flex-wrap: wrap;
            // display: grid;
            // gap: 1rem;
            // grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
            // grid-auto-rows: 240px;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .TimelinePage {
    .timeline-sec {
      align-items: center;
      .main-timeline-con {
        .date-con {
          display: none;
        }
        .timeline-con {
          padding-left: 0;
          width: 100%;

          .yearly-timeline {
            .card-sec {
              width: 100%;
              align-items: center;
              justify-content: center;
              padding: 0;
            }
          }
        }
      }
    }
  }
}
