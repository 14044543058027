.MusicLinkSec {
  margin: 2rem 4rem;
  @media (max-width: 720px) {
    margin: 2rem 0rem;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .title {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "righteous", cursive;
    font-size: x-large;
    font-weight: 400;
    // padding-bottom: 2rem;
  }
  .album-sec {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    .music-con {
      margin: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      // width: 250px;
      // height: 200px;
      // overflow: hidden;
      &:hover {
        img {
          box-shadow: 0px 3.44px 20px rgba(0, 0, 0, 0.6);
          filter: brightness(30%);
          transform: scale(1.05);
        }
        .listen-now-con {
          opacity: 1;
        }
      }
      img {
        border-radius: 8px;
        box-shadow: 0px 3.44px 17.2px rgba(0, 0, 0, 0.2);
        transition: all ease-in-out 0.2s;
        // width: 100%;
        width: 300px;
        height: 200px;
        object-fit: cover;
        @media (max-width: 570px) {
          width: 250px;
          height: 166px;
        }
        @media (max-width: 400px) {
          width: 200px;
          height: 133px;
        }
      }
      .listen-now-con {
        position: absolute;
        transition: all ease-in-out 0.2s;
        opacity: 0;
        color: white;
        // background-color: red;
        border-radius: 8px;
        padding: 5px;
        font-size: 20px;
        font-weight: 500;
        letter-spacing: 1.5px;
      }
    }
  }
  .read-more {
    margin-top: 2rem;
    .new-btn-sec {
      background-color: transparent;
      border: 1px solid #ff0000;
      color: #ff0000;
      padding: 8px;
      font-size: 14px;
      font-weight: 400;
      font-family: "righteous", cursive;
      cursor: pointer;
      border-radius: 8px;
      transition: 0.3s;
      display: flex;
      align-items: center;
      justify-content: center;
      .btn-icon {
        font-size: 1.4rem;
      }
    }
    .new-btn-sec:hover {
      color: #ffffff;
      background-color: #ff0000;
    }
  }
}
