.NewNavbar {
  padding-bottom: 66px;
  background-color: #fdd116;

  .main-logo {
    padding: 0;
  }
  .topnav {
    overflow: hidden;
    background-color: #fdd116;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 20;
    transition: all ease-in-out 0.3s;
  }

  .topnav a {
    color: #000000;
    text-align: center;
    padding: 14px;
    text-decoration: none;
    font-size: 16px;
    transition: all ease-in-out 0.2s;
  }

  .topnav {
    .right-sec {
      a:hover {
        color: #ff002a;
        font-size: 20px;
      }
    }
  }

  .topnav a.active {
    background-color: #4caf50;
    color: white;
  }

  .topnav .icon {
    display: none;
  }

  @media screen and (max-width: 1000px) {
    .topnav .right-sec {
      display: none;
    }
    .topnav .icon {
      padding: 1.2rem;
      display: block;
      img {
        cursor: pointer;
      }
    }
  }

  @media screen and (max-width: 1000px) {
    .topnav.responsive {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      position: absolute;
    }
    .topnav.responsive .icon {
      position: absolute;
      right: 0;
      top: 0;
      img {
        cursor: pointer;
      }
    }
    .topnav.responsive .right-sec {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      position: relative;
    }
  }
}
