.Footer {
  background-color: black;
  color: white;
  font-family: "Montserrat", sans-serif;
  text-align: center;
  .tbh {
    padding: 0.5rem;
    font-size: 14px;
    a {
      padding-left: 0.4rem;
      color: white;
      text-decoration: none;
    }
    a:hover {
      color: red;
    }
  }
}
