.BandPage {
  background-color: #f8f8f6;

  .image-con {
    width: 100%;
    img {
      width: 100%;
      max-height: 75vh;
      object-fit: cover;
      // object-position: top;
      // margin-top: -12rem;
    }
  }
  .band-members {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 1rem;
    .readmore-expanded {
      font-family: "Source Sans Pro", sans-serif;
      text-align: justify;
      letter-spacing: 0.15px;
      color: rgba(0, 0, 0, 0.6);
      line-height: 25px;
      max-width: 100%;
      width: 720px;
      font-size: 18px;
      // font-weight: 600;
    }
    .title {
      font-family: "righteous", cursive;
      margin: 2rem;
      margin-bottom: 0rem;
      font-size: 30px;
      text-align: center;
    }
    .sub {
      font-family: "Source Sans Pro", sans-serif;
      text-align: justify;
      letter-spacing: 0.15px;
      color: rgba(0, 0, 0, 0.6);
      line-height: 25px;
      max-width: 100%;
      width: 640px;
      padding-bottom: 2rem;
      font-size: 18px;
      // font-weight: 600;
    }
  }
  .members-sec {
    display: flex;
    justify-content: center;
    align-items: center;
    .section-again {
      width: 100%;
      max-width: 1000px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
      @media (max-width: 960px) {
      }
    }
    .section-again2 {
      width: 100%;
      max-width: 1000px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      @media (max-width: 960px) {
      }
    }
  }
  .about-more {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 2rem;
    span {
      font-family: "Source Sans Pro", sans-serif;
      font-size: 18px;
      // font-weight: 600;
      line-height: 24px;
      text-align: center;
      color: rgba(0, 0, 0, 0.6);
      max-width: 100%;
      width: 1077px;
    }
  }
  .technical-sec {
    // padding: 2rem 4rem;
    display: flex;
    flex-direction: column;
    .title {
      font-family: "righteous", cursive;
      // margin: 2rem;
      font-size: 30px;
      text-align: center;
    }
  }
  .white-space {
    height: 20vh;
  }
}
